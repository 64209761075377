import axios from "axios";

// MODULE
export async function requestGetModuleList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/master/modules?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestAddModule(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/master/modules/add`, data);
}

export async function requestEditModule(data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/master/modules/edit`, data);
}
// MODULE

// SCHOOL
export async function requestGetSchoolList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/master/schools?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestShowSchool(id) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/master/schools/show/`, {
		id: id,
	});
}

export async function requestAddSchool(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/master/schools/add`, data);
}

export async function requestEditSchool(data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/master/schools/edit`, data);
}
// SCHOOL

// USER
export async function requestGetUserList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/master/users?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestAddUser(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/master/users/add`, data);
}

export async function requestEditUser(data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/master/users/edit`, data);
}
// USER

// TRANSACTION
export async function requestGetTransactionList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/master/transactions?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestSettleTransaction(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/master/transactions/settle`, data);
}

export async function requestVoidTransaction(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/master/transactions/void`, data);
}
// TRANSACTION

// INVOICE
export async function requestGetInvoiceList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/master/invoices?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestAddInvoice(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/master/invoices/add`, data);
}

export async function requestEditInvoice(data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/master/invoices/edit`, data);
}

export async function requestUpdateInvoiceStatus(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/master/invoices/update-status`, data);
}

export async function requestGenerateInvoices(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/master/invoices/generate`, data);
}
// INVOICE

// SUBJECT
export async function requestGetSubjectList({
	page = 1,
	limit = 10,
	order = "name:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/master/subjects?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestAddSubject(data) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/master/subjects/add`, data);
}

export async function requestEditSubject(data) {
	return await axios.put(`${process.env.REACT_APP_API_URL}/master/subjects/edit`, data);
}

export async function requestDeleteSubject(id) {
	return await axios.delete(`${process.env.REACT_APP_API_URL}/master/subjects/delete`, {
		id: id,
	});
}
// SUBJECT

// BILL
export async function requestGetBillList({
	page = 1,
	limit = 10,
	order = "_id:desc",
	keyword = "",
	filter = "",
}) {
	return await axios.get(
		`${
			process.env.REACT_APP_API_URL
		}/master/bills?order=${order}&page=${page}&limit=${limit}&keyword=${encodeURIComponent(
			keyword
		)}&filter=${filter}`
	);
}

export async function requestAcceptBill(id) {
	return await axios.post(`${process.env.REACT_APP_API_URL}/master/bills/${id}/accept`);
}
// BILL
