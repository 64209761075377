/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { CheckCircleFilled, CloseCircleFilled, MoreOutlined } from "@ant-design/icons";
import {
	App,
	Button,
	Card,
	Col,
	Divider,
	Dropdown,
	Popconfirm,
	Row,
	Space,
	Table,
	Tag,
	Tooltip,
	Typography,
	message,
} from "antd";
import React, { useEffect, useState } from "react";
import {
	requestGetTransactionList,
	requestVoidTransaction,
} from "../../../services/master.service";
import {
	HiArrowDown,
	HiArrowUp,
	HiCheckCircle,
	HiOutlineClipboard,
	HiOutlineEye,
	HiPauseCircle,
} from "react-icons/hi2";
import dayjs from "dayjs";
import { format } from "../../../utils/Formatter";
import SearchInput from "../../../components/SearchInput";
import DateRangeFilter from "../../../components/DateRangeFilter";
import TransactionDetailModal from "./components/TransactionDetailModal";
import TransactionSettlementFormModal from "./components/TransactionSettlementFormModal";

const columns = [
	{
		title: "ID Transaksi",
		dataIndex: "id",
		key: "id",
		render: (text, record) => {
			return (
				<div
					style={{
						display: "flex",
						flexDirection: "column",
					}}>
					<span
						style={{
							display: "flex",
							alignItems: "center",
							fontWeight: "bold",
						}}>
						{text}
						<Tooltip title="Salin Nomor ID">
							<HiOutlineClipboard
								style={{
									cursor: "pointer",
									marginLeft: "2px",
								}}
								onClick={() => {
									navigator.clipboard.writeText(text);
									message.success("Berhasil menyalin nomor ID");
								}}
							/>
						</Tooltip>
					</span>
					<span>
						{record.school.name} ({record.school.number})
					</span>
				</div>
			);
		},
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
		render: (text, record) => {
			return text === "COMPLETED" ? (
				<Tag color="processing">Ditahan</Tag>
			) : text === "CREATED" ? (
				<Tag color="warning">Dibuat</Tag>
			) : text === "SUBMITTED" ? (
				<Tag color="warning">Pengecekan</Tag>
			) : text === "SETTLED" ? (
				<Tag color="success">Tuntas</Tag>
			) : text === "FAILED" ? (
				<Tag color="error">Gagal</Tag>
			) : (
				<Tag color="default">Tidak Sah</Tag>
			);
		},
	},
	{
		title: "Date",
		dataIndex: "date",
		key: "date",
		sorter: true,
	},
	{
		title: "Kategori",
		dataIndex: "category",
		key: "category",
		render: (text, record) => {
			return (
				<span>
					{record.type === "INCOME" ? (
						<Tag color="success">
							<HiArrowDown />
						</Tag>
					) : (
						<Tag color="error">
							<HiArrowUp />
						</Tag>
					)}
					{text}
				</span>
			);
		},
		filters: [
			{
				text: "Biaya Pendidikan",
				value: "TUITION",
			},
			{
				text: "Gaji Guru",
				value: "SALARY",
			},
			{
				text: "Biaya Operasional",
				value: "OPERATIONAL",
			},
			{
				text: "Biaya Lainnya",
				value: "OTHERS",
			},
		],
	},
	{
		title: "Total",
		dataIndex: "total",
		key: "total",
		render: (text, record) => {
			return format(text);
		},
	},
	{
		title: "Channel",
		dataIndex: "channel",
		key: "channel",
		render: (text, record) => {
			return <Tag color="default">{text}</Tag>;
		},
		filters: [
			{
				text: "Cash",
				value: "CASH",
			},
			{
				text: "Transfer",
				value: "TRANSFER",
			},
			{
				text: "Tabungan",
				value: "POCKET",
			},
			{
				text: "BCA",
				value: "BCA",
			},
			{
				text: "Mandiri",
				value: "MANDIRI",
			},
			{
				text: "Maybank",
				value: "MAYBANK",
			},
			{
				text: "BNI",
				value: "BNI",
			},
			{
				text: "CIMB",
				value: "CIMB",
			},
			{
				text: "Permata",
				value: "PERMATA",
			},
			{
				text: "ATM",
				value: "ATM",
			},
			{
				text: "Artha",
				value: "ARTHA",
			},
			{
				text: "BNC",
				value: "BNC",
			},
			{
				text: "BRI",
				value: "BRI",
			},
		],
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const TransactionPage = () => {
	const { message, notification, modal } = App.useApp();
	// const navigate = useNavigate();
	// const [searchParams] = useSearchParams();
	// const keyword = searchParams.get("keyword");
	// const filter = searchParams.get("filter");

	// SEARCH, FILTER, PAGINATION
	const [keyword, setKeyword] = useState("");
	const [filter, setFilter] = useState({
		status: "COMPLETED|SETTLED",
		startedAt: dayjs().startOf("month").format("YYYY-MM-DD"),
		endedAt: dayjs().endOf("month").format("YYYY-MM-DD"),
		channel: "CASH|TRANSFER|POCKET|BCA|MANDIRI|MAYBANK|BNI|CIMB|PERMATA|ATM|ARTHA|BNC|BRI",
	});
	const [sorter, setSorter] = useState({
		_id: "desc",
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	const handleSearch = (value) => {
		setKeyword(value.trim());
	};

	const handlePaginate = (pagination, filters, sorter) => {
		setPage(pagination.current);
		setLimit(pagination.pageSize);

		// UPDATE FILTER
		if (filters) {
			const additionalFilter = {};
			Object.keys(filters).forEach((key) => {
				if (filters[key]) {
					additionalFilter[key] = filters[key].join("|");
				} else {
					if (key === "status") {
						additionalFilter[key] = "COMPLETED|SETTLED";
					} else if (key === "channel") {
						additionalFilter[key] =
							"CASH|TRANSFER|POCKET|BCA|MANDIRI|MAYBANK|BNI|CIMB|PERMATA|ATM|ARTHA|BNC|BRI";
					} else {
						additionalFilter[key] = "";
					}
				}
			});

			setFilter({
				...filter,
				...additionalFilter,
			});
		} else {
			const tempFilter = {};
			// only allow startedAt and endedAt
			Object.keys(filter).forEach((key) => {
				if (key === "startedAt" || key === "endedAt") {
					tempFilter[key] = filter[key];
				}
			});

			setFilter(tempFilter);
		}

		// UPDATE SORTER
		if (sorter) {
			setSorter({
				[sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
			});
		} else {
			setSorter({
				_id: "desc",
			});
		}
	};
	// SEARCH, FILTER, PAGINATION

	const [transactions, setTransactions] = useState([]);
	const [summary, setSummary] = useState({
		completed: 0,
		settled: 0,
	});
	const [mappedTransactions, setMappedTransactions] = useState([]);
	const [isLoading, setLoading] = useState(false);

	const [transaction, setTransaction] = useState(null);
	const [isTransactionDetailVisible, setTransactionDetailVisible] = useState(false);
	const [isTransactionSettlementFormVisible, setTransactionSettlementFormVisible] = useState(false);

	const [selectedTransactions, setSelectedTransactions] = useState([]);

	useEffect(() => {
		fetchTransactionList();
	}, []);

	useEffect(() => {
		fetchTransactionList();
		setSelectedTransactions([]);
	}, [keyword, filter, page, limit, sorter]);

	const fetchTransactionList = () => {
		setLoading(true);

		let tempFilter = "";
		if (filter) {
			Object.keys(filter).forEach((key) => {
				if (filter[key]) {
					tempFilter += `${key}:${filter[key]},`;
				}
			});
		}

		let tempSorter = "";
		if (sorter) {
			Object.keys(sorter).forEach((key) => {
				if (sorter[key]) {
					tempSorter += `${key}:${sorter[key]}`;
				}
			});
		}

		requestGetTransactionList({
			page: page,
			limit: limit,
			order: tempSorter ?? "_id:desc",
			keyword: keyword ?? "",
			filter: tempFilter ?? "",
		})
			.then((response) => {
				setTransactions(response.data.data);
				setTotal(response.data.pagination.total);
				setSummary(response.data.summary);
				updateMappedTransaction(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateMappedTransaction = (data) => {
		setMappedTransactions(
			data.map((d) => {
				const otherButtons = [
					{
						key: "show",
						label: "Lihat",
						icon: <HiOutlineEye />,
					},
				];

				return {
					key: d._id,
					id: d._id,
					school: d.school,
					status: d.status,
					date: dayjs(d.date).format("DD MMMM YYYY HH:mm"),
					type: d.type,
					category: d.category,
					nominal: d.nominal,
					total: d.nominal + d.fees.map((f) => f.nominal).reduce((a, b) => a + b, 0),
					channel: d.channel,
					actions: (
						<Space>
							<Dropdown
								trigger={["click"]}
								menu={{
									onClick: (e) => {
										if (e.key === "show") {
											handleShowButton(d);
										}
									},
									items: otherButtons,
								}}
								placement="bottomRight"
								arrow>
								<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
							</Dropdown>
						</Space>
					),
				};
			})
		);
	};

	const handleSettleButton = () => {
		setTransactionSettlementFormVisible(true);
	};

	const handleVoidButton = () => {
		setLoading(true);

		const ids = selectedTransactions.map((transaction) => transaction._id);

		requestVoidTransaction({
			ids: ids,
		})
			.then((response) => {
				notification["success"]({
					message: "Berhasil!",
					description: response.data.message,
				});
				fetchTransactionList();
				setSelectedTransactions([]);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleShowButton = (transaction) => {
		setTransaction(transaction);
		setTransactionDetailVisible(true);
	};

	const handleOnCloseTransactionDetail = () => {
		setTransactionDetailVisible(false);
		setTransaction(null);
	};

	const rowSelection = {
		selectedRowKeys: selectedTransactions.map((transaction) => transaction._id),
		onChange: (selectedRowKeys, selectedRows) => {
			setSelectedTransactions(
				transactions.filter((transaction) => selectedRowKeys.includes(transaction._id))
			);
		},
		getCheckboxProps: (record) => ({
			disabled: record.status !== "COMPLETED",
		}),
	};

	const handleOnCloseTransactionSettlementForm = () => {
		setTransactionSettlementFormVisible(false);
	};

	const handleOnSuccessTransactionSettlementForm = () => {
		setTransactionSettlementFormVisible(false);
		fetchTransactionList();
		setSelectedTransactions([]);
	};

	return (
		<>
			<TransactionDetailModal
				isVisible={isTransactionDetailVisible}
				transaction={transaction}
				onClose={handleOnCloseTransactionDetail}
			/>
			<TransactionSettlementFormModal
				isVisible={isTransactionSettlementFormVisible}
				transactions={selectedTransactions}
				onClose={handleOnCloseTransactionSettlementForm}
				onSuccess={handleOnSuccessTransactionSettlementForm}
			/>
			<div className="tabled">
				<Row gutter={[24, 0]}>
					<Col xs={24} xl={24}>
						<Card
							bordered={false}
							className="circlebox tablespace mb-24"
							title="Daftar Transaksi"
							extra={
								<Space>
									<SearchInput placeholder="Masukkan ID" onSearch={handleSearch} />
									<DateRangeFilter
										onOk={(start, end) => {
											setFilter({
												startedAt: start,
												endedAt: end,
											});
										}}
										startedAt={filter.startedAt}
										endedAt={filter.endedAt}
									/>
									<Button
										icon={<CheckCircleFilled />}
										type="primary"
										style={{
											backgroundColor: "#52c41a",
											borderColor: "#52c41a",
										}}
										onClick={handleSettleButton}>
										<span className="ant-btn-text">
											Selesaikan (
											{format(
												selectedTransactions.map((row) => row.nominal).reduce((a, b) => a + b, 0)
											)}
											)
										</span>
									</Button>
									<Popconfirm
										title="Anda yakin akan membatalkan transaksi ini?"
										onConfirm={handleVoidButton}
										placement="bottomRight"
										okText="Ya"
										cancelText="Tidak">
										<Button icon={<CloseCircleFilled />} type="primary" danger>
											<span className="ant-btn-text">
												Batalkan (
												{format(
													selectedTransactions.map((row) => row.nominal).reduce((a, b) => a + b, 0)
												)}
												)
											</span>
										</Button>
									</Popconfirm>
								</Space>
							}>
							<Space
								direction="vertical"
								style={{
									width: "100%",
									padding: "12px 24px",
								}}>
								<Row gutter={[12, 12]}>
									<Col xs={24} xl={12}>
										<Card
											style={{
												backgroundColor: "#fff",
												borderRadius: 4,
												padding: 12,
												boxShadow: "none",
												border: "1px solid #e8e8e8",
											}}>
											<Space direction="horizontal" style={{ width: "100%" }}>
												<div
													style={{
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
														color: "#1890ff",
													}}>
													<HiPauseCircle size={48} />
												</div>
												<Col gutter={0}>
													<Typography.Title level={5} style={{ margin: 0 }}>
														Total Ditahan
													</Typography.Title>
													<Typography.Title level={2} style={{ margin: 0 }}>
														{format(summary.completed)}
													</Typography.Title>
												</Col>
											</Space>
										</Card>
									</Col>
									<Col xs={24} xl={12}>
										<Card
											style={{
												backgroundColor: "#fff",
												borderRadius: 4,
												padding: 12,
												boxShadow: "none",
												border: "1px solid #e8e8e8",
											}}>
											<Space direction="horizontal" style={{ width: "100%" }}>
												<div
													className="text-success"
													style={{
														display: "flex",
														alignItems: "center",
														justifyContent: "center",
													}}>
													<HiCheckCircle size={48} />
												</div>
												<Col gutter={0}>
													<Typography.Title level={5} style={{ margin: 0 }}>
														Total Tuntas
													</Typography.Title>
													<Typography.Title
														level={2}
														style={{ margin: 0 }}
														className="text-success">
														{format(summary.settled)}
													</Typography.Title>
												</Col>
											</Space>
										</Card>
									</Col>
								</Row>
							</Space>
							<Divider style={{ margin: "0" }} />
							<div>
								<Table
									columns={columns}
									loading={isLoading}
									dataSource={mappedTransactions}
									pagination={{
										total: total,
										position: "bottomRight",
										pageSizeOptions: ["10", "20", "50", "100"],
										showSizeChanger: true,
										locale: { items_per_page: "item/hal" },
									}}
									onChange={handlePaginate}
									className="ant-border-space"
									rowSelection={{
										...rowSelection,
									}}
								/>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default TransactionPage;
