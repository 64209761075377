/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { ExclamationCircleFilled, MoreOutlined } from "@ant-design/icons";
import { App, Button, Card, Col, Dropdown, Row, Space, Table, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { requestAcceptBill, requestGetBillList } from "../../../services/master.service";
import dayjs from "dayjs";
import { HiOutlineCheck } from "react-icons/hi2";
import SearchInput from "../../../components/SearchInput";

const columns = [
	{
		title: "No.",
		dataIndex: "number",
		key: "number",
		sorter: true,
		render: (text, record) => {
			return (
				<Space direction="vertical" size={0}>
					<Typography.Text
						copyable
						style={{
							fontFamily: "monospace",
							fontWeight: "bold",
						}}>
						{record.number}
					</Typography.Text>
					<Typography.Text type="secondary">
						{dayjs(record.issuedAt).format("DD MMMM YYYY")}
					</Typography.Text>
				</Space>
			);
		},
	},
	{
		title: "Sekolah",
		dataIndex: "bill",
		key: "bill",
		sorter: true,
		render: (text, record) => {
			return (
				<Space direction="vertical" size={0}>
					<Typography.Text>{record.school.name}</Typography.Text>
				</Space>
			);
		},
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
		sorter: true,
		render: (text, record) => {
			let status =
				record.status === "PAID" ? "Lunas" : record.status === "CREATED" ? "Belum lunas" : "Gagal";
			let color = "geekblue";
			if (record.status === "CREATED") {
				color = "volcano";
			} else if (record.status === "PAID") {
				color = "green";
			}
			return <Tag color={color}>{status}</Tag>;
		},
	},
	{
		title: "Jatuh Tempo",
		dataIndex: "dueAt",
		key: "dueAt",
		sorter: true,
		render: (text, record) => {
			return dayjs(text).format("DD MMMM YYYY");
		},
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const otherButtons = [
	// {
	// 	key: "show",
	// 	label: "Lihat",
	// 	icon: <HiOutlineEye />,
	// },
	{
		key: "accept",
		label: "Terima",
		icon: <HiOutlineCheck />,
	},
	// {
	// 	type: "divider",
	// },
	// {
	// 	key: "delete",
	// 	label: "Hapus",
	// 	icon: <HiOutlineTrash />,
	// 	danger: true,
	// },
];

const BillPage = () => {
	const { message, notification, modal } = App.useApp();

	// SEARCH, FILTER, PAGINATION
	const [keyword, setKeyword] = useState("");
	const [filter, setFilter] = useState({
		startedAt: dayjs().startOf("month").format("YYYY-MM-DD"),
		endedAt: dayjs().endOf("month").format("YYYY-MM-DD"),
	});
	const [sorter, setSorter] = useState({
		_id: "desc",
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	const handleSearch = (value) => {
		setKeyword(value.trim());
	};

	const handlePaginate = (pagination, filters, sorter) => {
		setPage(pagination.current);
		setLimit(pagination.pageSize);

		// UPDATE FILTER
		if (filters) {
			const additionalFilter = {};
			Object.keys(filters).forEach((key) => {
				if (filters[key]) {
					additionalFilter[key] = filters[key].join("|");
				} else {
					additionalFilter[key] = "";
				}
			});

			setFilter({
				...filter,
				...additionalFilter,
			});
		} else {
			const tempFilter = {};
			// only allow startedAt and endedAt
			Object.keys(filter).forEach((key) => {
				if (key === "startedAt" || key === "endedAt") {
					tempFilter[key] = filter[key];
				}
			});

			setFilter(tempFilter);
		}

		// UPDATE SORTER
		if (sorter) {
			setSorter({
				[sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
			});
		} else {
			setSorter({
				_id: "desc",
			});
		}
	};
	// SEARCH, FILTER, PAGINATION

	const [bills, setBills] = useState([]);
	const [mappedBills, setMappedBills] = useState([]);
	const [isLoading, setLoading] = useState(false);

	useEffect(() => {
		fetchBillList();
	}, [keyword, filter, page, limit]);

	const fetchBillList = () => {
		setLoading(true);
		let tempFilter = "";
		if (filter) {
			Object.keys(filter).forEach((key) => {
				if (filter[key]) {
					tempFilter += `${key}:${filter[key]},`;
				}
			});
		}

		let tempSorter = "";
		if (sorter) {
			Object.keys(sorter).forEach((key) => {
				if (sorter[key]) {
					tempSorter += `${key}:${sorter[key]}`;
				}
			});
		}

		requestGetBillList({
			page: page,
			limit: limit,
			order: tempSorter ?? "_id:desc",
			keyword: keyword ?? "",
			filter: tempFilter ?? "",
		})
			.then((response) => {
				setBills(response.data.data);
				updateMappedBill(response.data.data);
				setTotal(response.data.pagination.total);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleAcceptButton = (id) => {
		requestAcceptBill(id)
			.then((response) => {
				message.success("Pembayaran berhasil diterima");
				fetchBillList();
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			});
	};

	const updateMappedBill = (data) => {
		setMappedBills(
			data.map((d) => ({
				key: d._id,
				number: d.number,
				school: d.school,
				issuedAt: d.issuedAt,
				dueAt: d.dueAt,
				status: d.status,
				actions: (
					<Space>
						<Dropdown
							trigger={["click"]}
							menu={{
								onClick: (e) => {
									if (e.key === "accept") {
										modal.confirm({
											title: "Sebentar",
											icon: <ExclamationCircleFilled />,
											content: "Apakah Anda yakin ingin menerima pembayaran ini?",
											okText: "Ya",
											okType: "danger",
											cancelText: "Tidak",
											onOk() {
												handleAcceptButton(d._id);
											},
											onCancel() {},
										});
									}
								},
								items: otherButtons,
							}}
							placement="bottomRight"
							arrow>
							<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
						</Dropdown>
					</Space>
				),
			}))
		);
	};

	return (
		<>
			<div className="tabled">
				<Row gutter={[24, 0]}>
					<Col xs={24} xl={24}>
						<Card
							bordered={false}
							className="circlebox tablespace mb-24"
							title="Daftar Sekolah"
							extra={
								<Space>
									<SearchInput placeholder="Nama sekolah" onSearch={handleSearch} />
								</Space>
							}>
							<div>
								<Table
									columns={columns}
									loading={isLoading}
									dataSource={mappedBills}
									pagination={{
										total: total,
										position: "bottomRight",
										pageSizeOptions: ["10", "20", "50", "100"],
										showSizeChanger: true,
										locale: { items_per_page: "item/hal" },
									}}
									onChange={handlePaginate}
									className="ant-border-space"
								/>
							</div>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default BillPage;
